import './App.css';
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import {useEffect, useReducer, useState} from "react";
import {toast, ToastContainer} from "react-toastify";

export const serverAddress = process.env.SERVER_NAME
const initialState = {
  isLogin: null,
  userId: null,
  accessToken: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      // sessionStorage.setItem("userId", action.payload.userId);
      sessionStorage.setItem("accessToken", action.payload.accessToken);
      return {
        ...state,
        isLogin: true,
        userId: action.payload.userId,
        accessToken: action.payload.accessToken,
      };
    case "LOGOUT":
      // localStorage.removeItem("userId");
      sessionStorage.removeItem("accessToken");
      return {
        ...state,
        isLogin: false,
        userId: null,
        accessToken: null,
      };
  }
}





function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [account, setAccount] = useState();
  const [password, setPassword] = useState();
  const [file, setFile] = useState();
  const [name, setName] = useState();
  const [discrim, setDis] = useState();
  const [result, setResult] = useState("");

  const handleLogin = () => {
    fetch("/api/v1/keeper/auth",{
      method:"POST",
      body: JSON.stringify({ appId: account, appPass: password }),
    }).then((res) => {res.json().then((r) => {
      if(r.returnCode === '20000'){
        dispatch({
          type: "LOGIN",
          payload: {
            isLogin: true,
            accessToken: r.data.celebrityToken,
          },
        });
      }
    })})
  }

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT"
    });
  }

  const uploadFile = () => {
    const formData = new FormData();
    console.log(file);
    formData.append('whitelist', file);
    fetch("/api/v1/redemption/uploadWhitelistWinners",{
      headers: {"celebrityToken": state.accessToken},
      method:"POST",
      body:formData
    }).then((res) => {res.json().then((r) => {
      if(r.returnCode === '20000'){
        toast("Successfully Uploaded!");
      }
    })})
  }

  const search = () => {
    fetch("/api/v1/redemption/getUserClaimedOrNot",{
      headers: {"celebrityToken": state.accessToken},
      method:"POST",
      body: JSON.stringify({ "discord_name": name,
        "discord_discriminator": discrim }),
    }).then((res) => {res.json().then((r) => {
      if(r.returnCode === '20000'){
        setResult("Claimed: " + r.data.claimed + " Unclaimed: " + r.data.unclaimed);
      }
    })})
  }

  useEffect(() => {
    if(sessionStorage.getItem("accessToken") && sessionStorage.getItem("accessToken").length > 1){
      dispatch({
        type: "LOGIN",
        payload: {
          isLogin: true,
          accessToken: sessionStorage.getItem("accessToken"),
        },
      });
    }
  })
  return (
    <div className="App">
      {state.isLogin ?
          <div>
            <Button variant="outlined" onClick={handleLogout}>Logout</Button>
            <Input type="file" onChange={(e) => {setFile(e.target.files[0])}}/>
            <Button variant="outlined" onClick={uploadFile}>Upload</Button>

            <div style={{margin: '20px'}}>
              <Input placeholder="discord name" value={name} onChange={(e) => {setName(e.target.value)}}/>
              <Input  placeholder="discord discriminator" value={discrim} onChange={(e) => {setDis(e.target.value)}}/>
              <Button variant="outlined" onClick={search}>Search</Button>

              <div>
                {result}
              </div>
            </div>
          </div> :
          <div style={{display:'flex', flexDirection:'column', width: '300px'}}>
            <Input placeholder="account" value={account} onChange={(e) => {setAccount(e.target.value)}}/>
            <Input placeholder="password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
            <Button variant="outlined" onClick={handleLogin}>Login</Button>

          </div>
      }
      <ToastContainer />
    </div>
  );
}

export default App;
